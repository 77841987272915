/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"The Jazz Garden"} description={"The Jazz Garden"}>
        <Column className="pb--60 pt--60" name={"ay7xm72drfe"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Tiptoe Elephant"}>
              </Title>

              <Text className="text-box" content={"Hravá melodie<br>Joyfull melody"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" url={"https://otto-o.com/"} href={"https://otto-o.com/"} target={null} content={"Home"}>
              </Button>

              <YouTube embedId={"x985eN-HXXk"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cpn03tp4arg"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Gardener´s Spade"}>
              </Title>

              <Text className="text-box" content={"Rap pro jazzovou kapelu<br>Rap for the jazz band<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=mIY-kACSLY0&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=2"} href={"https://www.youtube.com/watch?v=mIY-kACSLY0&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=2"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Zen Wind"}>
              </Title>

              <Text className="text-box" content={"Cool jazz"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=eeKooliwkz8&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=3"} href={"https://www.youtube.com/watch?v=eeKooliwkz8&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=3"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Quarrelsome Squirrel"}>
              </Title>

              <Text className="text-box" content={"Čistý saxofonový kvartet<br>Pure saxophone quartet<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=l_ZFoeEuIts&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=5"} href={"https://www.youtube.com/watch?v=l_ZFoeEuIts&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=5"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"gruaxnh5og"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"The Jazz Roots"}>
              </Title>

              <Text className="text-box" content={"Swing s jazzovou trumpetou<br>Swing with the jazz trumpet<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=p4XcYjffVAQ&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=6"} href={"https://www.youtube.com/watch?v=p4XcYjffVAQ&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=6"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Too Many Frogs"}>
              </Title>

              <Text className="text-box" content={"Avandgardní kompozice pro saxofonové kvarteto<br>Avandguarde composition for the saxophone quartet<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=qfkiYdJdVbE&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=7"} href={"https://www.youtube.com/watch?v=qfkiYdJdVbE&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=7"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Goddess Eywa"}>
              </Title>

              <Text className="text-box" content={"Modern jazz"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=s0765i2Eb_U&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=4"} href={"https://www.youtube.com/watch?v=s0765i2Eb_U&list=PLkTj2Nk6txug7ke0_33uDM9he3_JalXr6&index=4"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}